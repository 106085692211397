<template>
  <div class="live">
    <Header :active="'trainLive'" />
    <Video :isPeixun="true" />
    <div></div>
  </div>
</template>

<script>
import Header from "@/components/header";
import Video from "./components/video.vue";
import api from "@/api/serves/live";
export default {
  name: "trainLive",
  components: {
    Header,
    Video,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.live {
  min-height: 100%;
  padding: 135px 0;
  background: #005aa4;
  box-sizing: border-box;
}
</style>